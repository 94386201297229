import React from 'react';

const Dashboard = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col s12">
                    <h1>Your Dashboard</h1>
                    <p>Here you can see all your surveys in one place...</p>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;