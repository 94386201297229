import React from 'react';

const Landing = () => {
    return (
        <div className="container">
            <div className="row" style={{ textAlign: "center" }}>
                <div className="col s12">
                    <h1>Welcome to Emaily!</h1>
                    <p>The very best place to make your custom Surveys...</p>
                </div>
            </div>
        </div>
    )
}

export default Landing;